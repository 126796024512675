@use "../../styles/variables" as *;

.login{
    display: flex;
    flex-direction: column;
    align-items: center;
    &__input{
        background-color: $secondary-white;
    }

    &__content{
        color: $secondary-white;
    }

    &__link{
        text-decoration: none;
    }
}

.lidden{
    opacity: 0;
    filter: blur(5px);
    transform: translateX(-100%);
    transition: all 1s;
}

.lhow{
    opacity: 1;
    filter: blur(0);
    transform: translateX(0);
}