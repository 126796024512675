.dashboard{
    display: flex;
    justify-content: center;
    margin: auto;
}

.zidden{
    opacity: 0;
    filter: blur(5px);
    transform: translateX(-100%);
    transition: all 1s;
}

.zhow{
    opacity: 1;
    filter: blur(0);
    transform: translateX(0);
}