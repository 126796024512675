@use "../../styles/variables" as *;
@use "../../styles/mixins" as *;


.income {
    border: 2px solid $secondary-blue-2;
    box-shadow: 1px 5px 10px 5px rgb(45, 104, 176, .7);
    border-radius: 8px;
    width: 100%;
    height: 13rem;
    color: $secondary-white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: auto;
    padding: 1rem 0.5rem;
    margin-bottom: 1rem;

    @include tablet{
        width: 100%;
        height: 30vh;
        margin: 0.5rem;
        padding: 1rem 0.4rem;

    }

    @include desktop{
        width: 33%;
        height: 40vh;
    }

    &__form{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    &__input {
        background-color: $secondary-white;
        border-radius: 4px;
        border: none;
    }

    &__header {
        font-weight: 600;
        @include tablet{
            font-size: 2rem;
        }
    }

    &__amount{
        @include tablet{
            font-size: 2rem;
        }
    }

    &__button {
        
        &-add {
            background-color: $secondary-blue-2;
            color: $secondary-white;
            padding: 0.5rem 1rem;
            border: none;
            border-radius: 12px;
            margin-top: .5rem;
            max-width: 5rem;
            transition: 0.3s;
        }
        &-add:hover{
            background-color: $secondary-blue-3;
        }

        &-change {
            background-color: $secondary-blue-2;
            color: $secondary-white;
            padding: 0.5rem 1rem;
            border: none;
            border-radius: 12px;
            margin-top: .5rem;
            transition: 0.3s;
        }
        &-change:hover{
            background-color: $secondary-blue-3;
        }
    }
}
