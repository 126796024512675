@use "../../../styles/variables" as *;

.auth {
    padding: 3rem;

    &__container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        &-email {
            display: flex;
            flex-direction: column;
            padding: 3rem;
        }
    }

    &__label{
        color: $secondary-white;
        display: flex;
        flex-direction: column;
        font-size: 1.5rem;
    }

    &__input-email {
        background-color: $secondary-white;
        border-radius: 8px;
        margin: 1rem 0;
        height: 2.5rem;
        padding: .5rem 1rem;
    }

    &__input-password {
        background-color: $secondary-white;
        border-radius: 8px;
        margin: 1rem 0;
        height: 2.5rem;
        padding: .5rem 1rem;
    }

    &__button-signin{
        background-color: $secondary-blue-2;
        color: $secondary-white;
        border-radius: 8px;
        border: none;
        padding: .5rem 1rem;
        min-width: 10.5rem;
        transition: 0.3s;
        font-size: 1.5rem;
        
    }
    &__button-signin:hover{
        background-color: $secondary-blue-3;
    }

    &__button-google{
        background-color: $google;
        color: $secondary-white;
        border-radius: 8px;
        border: none;
        padding: .5rem 4.5rem;
        font-weight: 800;
        min-width: 10.5rem;
        transition: 0.3s;
        font-size: 1.5rem;
        margin-top: 1rem;
    }

    &__button-google:hover{
        background-color: $google-2;
    }

    &__or{
        color: $secondary-white;
    }

    &__demo{
        color: rgb(202, 200, 200);
        margin-top: .25rem;
    }


}
