@use "../../styles/variables" as *;

.register {
    width: 100%;
    background-color: $secondary-blue;
    right: 0%;
    top: 0%;
    display: flex;
    justify-content: center;
    align-items: center;

    &__container {
        width: 50%;
        height: 60%;
        border-radius: 12px;
        color: $secondary-white;
        background-color: $secondary-blue;
        font-family: 'averta';
        box-shadow: rgba(0, 0, 0, 0.35);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 1.5rem;
        overflow: hidden;
    }

    &__header{
        margin-bottom: 3rem;
        font-weight: 800;
        color: $secondary-blue-2;
    }

    &__form {
        display: flex;
        flex-direction: column;
        align-items: center;

        &-input{
            background-color: $secondary-white;
            border-radius: 8px;
            margin-bottom: 1rem;
            padding: .5rem 1rem;
        }
    }
}
