@use '../../styles/variables' as *;
@use '../../styles/mixins' as *;


.netIncome{
    border: 2px solid $secondary-blue-2;
    box-shadow: 1px 5px 10px 5px rgb(45, 104, 176, .7);
    border-radius: 8px;
    width: 100%;
    height: 13rem;
    color: $secondary-white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: auto;
    margin-bottom: 1rem;
    

    @include tablet{
        width: 100%;
        height: 30vh;
        padding: 1rem .5rem;
        margin: .5rem;
    }

    @include desktop{
        width: 33%;
        height: 40vh;
    }

    &__total{
        font-weight: 800;
        @include tablet{
            font-size: 2rem;
        }
    }

    &__amount{
        
        @include tablet{
            font-size: 2rem;
        }
    }
}