@use "../../styles/variables" as *;
@use "../../styles/mixins" as *;

.benefits {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 1.5rem 2.25rem;
    padding: 2rem;
    height: 70rem;

    @include tablet{
        height: 70rem;
    }

    @include desktop{
        flex-direction: row;
        height: 45rem;
    }


    &__subheader{
        color: $secondary-blue-2;
        font-weight: 800;
        font-size: 1.25rem;

        @include tablet{
            font-size: 1.75rem;
        }
    }

    &__description{
        color: $secondary-white;
        font-size: 1rem;

        @include tablet{
            font-size: 1.5rem;
        }
    }

    &__container {

        &-image {
            padding: 5rem 1rem;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        &-content{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            @include tablet{
                justify-content: unset;
                align-items: unset;
            }
        }

    }

    &__image {
        height: 10rem;
        width: 100%;

        @include tablet{
            height: 20rem;
            width: 30rem; 
        }
    }

    
}

.bidden{
    opacity: 0;
    filter: blur(5px);
    transform: translateX(-100%);
    transition: all 1s;
}

.bhow{
    opacity: 1;
    filter: blur(0);
    transform: translateX(0);
}