@use '../../styles/variables' as *;
@use '../../styles/mixins' as *;


.budgetCard{
    width: 100%;
    padding: 1rem;
    &-container-header{
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: baseline;


        &-amount{
            font-size: 16px;
            color: $secondary-white;
        }

        &-max{
            font-size: 12px;
            color: $secondary-gray;
        }
        
    }

    &-progressBar{
        border-radius: 12px;
    }

    &-container-buttons{
        display: flex;
        justify-content: space-between;
        padding: 1rem 0;

        @include tablet {
            justify-content: flex-end; 
        }
    }

    &-button-add{
        background-color: $secondary-white;
        color: $secondary-blue-2;
        border-color: $secondary-blue-2;
        padding: .75rem 1.5rem;
        border-radius: 12px;
        margin: 0 .1rem;
    }
    
    &-button-view{
        background-color: $secondary-white;
        color: $secondary-blue-2;
        border-color: $secondary-blue-2;
        padding: .75rem 1.5rem;
        border-radius: 12px;
        margin: 0 1rem;
    }
}

.bg-primary{
    color:  rgb(0, 255, 4);
}
.--bs-progress-bar-bg{
    color:  rgb(0, 255, 4)
}