@use "../../styles/variables" as *;
@use "../../styles/mixins" as *;

.features{
    display: flex;
    flex-direction: column;
    padding: 2rem;
    height: 70rem;
    @include tablet{
        height: 30rem;
    }
    
    @include tablet{
        margin-bottom: 15rem;
        flex-direction: row;
        height: 20rem;
    }

    &__container{
        border: 1px solid $secondary-blue-2;
        box-shadow: 1px 5px 10px 5px rgb(45, 104, 176, .7);
        margin-bottom: 2rem;
        border-radius: 12px;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 2rem;
        @include tablet {
            width: 30%;
            margin: auto;
        }

        @include desktop{
            height: 18rem;
        }
    }

    &__container-content{
        margin-top: 2rem;
    }

    &__icon{
        height: 5rem;
        width: 5rem;
    }

    &__subheader{
        color: $secondary-blue-2;
    }

    &__description{
        color: $secondary-white;
    }
}

.midden{
    opacity: 0;
    filter: blur(5px);
    transform: translateX(100%);
    transition: all 1s;
}

.mhow{
    opacity: 1;
    filter: blur(0);
    transform: translateX(0);
}