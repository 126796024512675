@use '../../styles/variables' as *; 
@use '../../styles/mixins' as *;

.navBar{
    @include desktop{
    max-height: 8rem;
    color: $secondary-white;
    margin-top: 2rem;
    padding: 0 3rem;
    }
    
    &__content{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 1rem;

        @include tablet{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 2rem 5rem;
        }

        @include desktop{
            flex-direction: row;
        }
        

        &-profile-container{
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            width: 100%;
            margin-top: 1rem;

            @include tablet{
                width: unset;
                justify-content: flex-end;
                margin-top: unset;
                padding: 1rem;
            }

            
        }
    }
    &__logo{
        height: 4.5rem;
    }

    &__profile-avatar{
        border-radius: 50%;
        width: 4rem;
        height: 4rem;
        margin: 0 1rem;
    }

    &__button{
        display: flex;
        justify-content: center;
        align-items: center;
        text-decoration: none;
        padding: .25rem .5rem;
        border-radius: 8px;
        color: $secondary-white;
        background-color: $secondary-blue-2;
        border: none;
        width: 100%;

        @include tablet{
            padding: 1rem 2rem;
            margin: 0 1rem;
            transition: 0.3s;
            margin-top: unset;
            width: unset;
            height: 3rem;

        }
    }
    &__button:hover{
        background-color: $secondary-blue-3;
    }

    &__loggedIn-container{
        display: flex;
        flex-direction: column;
        margin-top: 1rem;
        color: $secondary-white;

        @include tablet{
            display: flex;
            justify-content: center;
            align-items: center;
        }
        
        @include desktop{
            flex-direction: row;

        }
    }
}