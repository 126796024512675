@font-face {
    font-family: 'averta';
    src: url(".././assets/fonts/AvertaDemoPECuttedDemo-Regular.otf") format("opentype");
    font-weight: 400;
}

@font-face {
    font-family: 'averta';
    src: url(".././assets/fonts/AvertaDemoPE-ExtraBold.otf") format("opentype");
    font-weight: 800;
}