@use "../../styles/variables" as *;
@use "../../styles/mixins" as *;

.hero {
    color: $secondary-white;
    display: flex;
    justify-content: center;
    flex-direction: column-reverse;
    align-items: center;
    height: 40rem;
    margin: auto;
    margin-top: 1rem;
    padding: 2.25rem;

    @include tablet {
        justify-content: center;
        align-items: center;
        height: 50rem;
        padding: 2rem;
        margin: auto;
    }

    @include desktop{
        flex-direction: row;
        margin: auto 3rem;
    }

    &__content {
        background-color: transparent;
        font-size: 1rem;
        @include tablet {
            font-size: 2rem;
        }

        &-container {
            width: 100%;
            padding: 1rem;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            @include tablet {
                padding: 1.5rem;
                background-color: transparent;
                display: unset;
            }
            @include desktop{
                width: 50%;
            }
        }
        &-title {
            color: $secondary-blue-2;
            font-weight: 800;
            font-size: 1.5rem;

            @include tablet {
                font-size: 3rem;
                font-weight: 800;
            }
        }
    }
    &__button {
        padding: 1rem 2rem;
        border-radius: 8px;
        color: $secondary-white;
        background-color: $secondary-blue-2;
        border: none;
        transition: 0.3s;
        margin-top: 1rem;

        @include tablet {
            font-size: 2rem;
            font-weight: 800;
        }
    }
    &__button:hover {
        background-color: $secondary-blue-3;
    }
    &__image-container {
        // width: 50%;
        padding: 1rem;
        margin: auto;
        background-color: transparent;
    }
    &__image {
        width: 15rem;
        height: 15rem;

        @include tablet {
            width: 30rem;
            height: 30rem;
            background-color: transparent;
        }
    }
}

.hidden {
    opacity: 0;
    filter: blur(5px);
    transition: all 1s;
}

.show {
    opacity: 1;
    filter: blur(0);
    transition: all 1s;
}
