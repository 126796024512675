@use '../../styles/variables' as *;
@use '../../styles/mixins' as *;

.budgetOverview{
    border: 2px solid $secondary-blue-2;
    box-shadow: 1px 5px 10px 5px rgb(45, 104, 176, .7);
    border-radius: 8px;
    width: 99%;
    height: 100%;
    color: $secondary-white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: .25rem;
    padding: 1rem;
    overflow: auto;

    &__container-header{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding: 0 1rem;
        margin-bottom: 1rem;

        @include tablet {
            justify-content: space-between;
            flex-direction: row;
            align-items: baseline;
        }
    }
    &__header{
        font-weight: 800;
        &-button-add{
            background-color: $secondary-blue-2;
            color: $secondary-white;
            border-radius: 8px;
            border: none;
            padding: .5rem 1rem;
            transition: 0.3s;
            margin: 0 1rem;

            @include tablet{
                padding: 1rem 2rem;

            }
        }
        &-button-add:hover{
            background-color: $secondary-blue-3;
        }
        &-button-expense{
            background-color: $secondary-white;
            color: $secondary-blue-2;
            border-radius: 8px;
            border: none;
            padding: .5rem 1rem;
            margin: 0 1rem;

            @include tablet{
                padding: 1rem 2rem;
            }
        }
    }

    &__container-buttons{
        display: flex;
    }

    &__category-container{
        width: 90%;
        margin: .5rem 0;
    }
}