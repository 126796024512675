// breakpoints 
$breakpoint-tablet: 48rem;
$breakpoint-desktop: 80rem;
$breakpoint-xl-desktop: 120rem;

// theme colors
$primary-green: #4D7E3E;
$primary-teal: #31C48D;
$primary-blue: #471eda;
$secondary-white: #FFFFFF;
$secondary-red: #e74249;
$secondary-gray: #b7c8d3;
$secondary-purple: #685eac;
$secondary-green: #00FF04;
$secondary-pink: #d689d6;
$secondary-blue-2: #599EF2;
$secondary-blue-3: #2d68b0;
$secondary-blue: #020911;
$secondary-black: #0b0f16;
$google: #DB4437;
$google-2: #aa2b20;
