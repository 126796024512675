@use '../../styles/variables' as *;

.addBudgetModal{
    color: $secondary-white;
    &__form{
        border: 2px solid $secondary-blue-2;
    }

    &__container-header{
        display: flex;
    }

    &__container-button{
        display: flex;
        justify-content: flex-end;
    }
    &__button{
        background-color: $secondary-white;
        color: $secondary-blue-2;
        border: 1px solid $secondary-blue-2;
        font-size: 16px;
        padding: .5rem 1rem;
        border-radius: 12px;
    }
}

.modal-content{
    border: 2px solid $secondary-blue-2;
}