@use "../../styles/variables" as *;

.footer{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1rem;
    margin: auto;
    height: 10rem;
    margin-bottom: 3rem;

    &__container-social{
        display: flex;
        flex-direction: row;
        justify-content: center;
        
    }

    &__social{
        width: 2.5rem;
        height: 2.5rem;
        margin: 0 1rem
    }
}