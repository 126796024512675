@use "../../styles/variables" as *;
@use '../../styles/mixins' as *;

.register{
    display: flex;
    justify-content: center;
    flex-direction: column;
    &__content{
        color: $secondary-white;
        font-size: 1.5rem;
        margin-top: 1rem;
    }

    &__link{
        text-decoration: none;
    }
}

.ridden{
    opacity: 0;
    filter: blur(5px);
    transform: translateX(-100%);
    transition: all 1s;
}

.rhow{
    opacity: 1;
    filter: blur(0);
    transform: translateX(0);
}